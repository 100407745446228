import { template as template_02bc8f7f6224410a8ff690d6f1f9203c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_02bc8f7f6224410a8ff690d6f1f9203c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
