import { template as template_df5bfdba96f44daaae764732c255f7e3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_df5bfdba96f44daaae764732c255f7e3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
