import { template as template_34d74f820db04b0eb79f32a626edba14 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_34d74f820db04b0eb79f32a626edba14(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
